<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

import {setToken} from "@/utils/token/jwtService";

export default {
  name: 'app',
  created() {
    // TODO 设置长期jwt 2024-11-29
    // setToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaG9uZSI6IjE4ODU5MjQzNTM2Iiwib3BlbmlkIjoib0xROE02aTM1WTFJV2tlbENHdUYzY2U1c05XNCIsInNleCI6MSwibmlja25hbWUiOiJlZWUiLCJyZWFsZW5hbWUiOiJjenQiLCJpZCI6NTIsInN0YXRlIjpmYWxzZSwiZXhwIjoxNzM4MDI5Nzc0LCJlbWFpbCI6IjEwODU3MjM3NDZAcXEuY29tIiwiaW50cm9kdWN0aW9uIjoiMTExIn0.vgEdGG1XFXnNayMafnMvV3JxrDUS4lUKcHoHV1Vwfr8')
    window.$message = this.$message
    window.$router = this.$router
    if (this.$route.name === 'Setting') {
      this.$router.push('/index')
    }
  }
}
</script>

<style>
*, body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
  user-select: none;
}


/*css主要部分的样式*/
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/

::-webkit-scrollbar {
  width: 0px; /*对垂直流动条有效*/
  height: 0px; /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius: 3px;
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #E8E8E8;
}

/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
}

/*定义右下角汇合处的样式*/
::-webkit-scrollbar-corner {
}

.el-popover {
  min-width: 90px !important;
}
</style>
