<script>
import * as req from "@/utils/request";
import RetrieveYourPassword from "@/components/fromCom/retrieveYourPassword.vue";

export default {
  name: 'hgSetting',
  components: {RetrieveYourPassword},
  data() {
    var checkPhone = (rule, value, callback) => { // 手机号验证
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^1[3456789]\d{9}$/
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      showUpdatePassword: false,
      showUpdatePhone: false,
      ruleForm: {
        realname: '',
        nickname: '',
        email: '',
        sex: '',
        introduction: ''
      },
      imageUrl: null,
      phoneForm: {
        phone: '',
        code: ''
      },
      codeStr: '获取验证码',
      rules: {
        email: [
          {required: true, message: '请输入邮箱地址', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
        nickname: [
          {required: true, message: '请输入昵称', trigger: 'blur'},
          {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: ['change', 'blur']}
        ],
        realname: [
          {required: true, message: '请输入真实姓名', trigger: 'blur'},
          {min: 2, max: 4, message: '长度在 2 到 4 个字符', trigger: 'blur'}
        ],
        introduction: [
          {required: true, message: '请填写介绍', trigger: 'blur'},
          {min: 0, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur'}
        ],
        phone: [
          {required: false, message: "请输入正确手机号", trigger: "blur"},
          {validator: checkPhone, trigger: 'blur'}
        ],
      },
    };
  },
  computed: {
    getPhone() {
      let user = window.$global.userDetails
      if (!user) return '-'
      if (!user.sysUser) return ''
      return user.sysUser.phone
    },
  },
  methods: {
    // 获取验证码
    sendSms() {
      this.$refs['bindPhone'].validate((valid) => {
        if (valid) {
          let sendData = {}
          sendData.data = this.phoneForm
          sendData.fn = this._sendSmsFn
          sendData.vm = this
          req.Post('code/verify', sendData)
        } else {
          this.$message.error('请输入正确信息')
          return false;
        }
      })
    },
    countdown() {
      this.codeStr = 60
      let timer = setInterval(() => {
        this.codeStr--
        if (this.codeStr <= 0) {
          clearInterval(timer)
          this.codeStr = '获取验证码'
        }
      }, 1000)
    },
    _sendSmsFn(data) {
      if (!data) return
      if (data.code === 200) {
        this.$message.success('发送成功')
        this.countdown()
        return;
      }
      this.$message.error(data.message)
    },
    updatePhone() {
      this.$refs['bindPhone'].validate((valid) => {
        if (valid) {
          let sendData = {}
          sendData.data = this.phoneForm
          sendData.fn = this._updatePhoneFn
          sendData.vm = this
          req.Post('sysUser/updatePhone', sendData)
        }
      })
    },
    _updatePhoneFn(data) {
      if (!data) return
      if (data.code === 200) {
        this.$message.success('绑定成功! 请重新登录')
        this.$router.push('/login')
        return
      }
      this.$message.error(data.message)
    },
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          this.$message.error('请输入正确信息')
          return
        }
        if (this.ruleForm.nickname.length >= 21) {
          this.$message.error('请输入正确昵称')
          return;
        }
        let info = this.ruleForm.introduction
        if (info.length <= 0 || info.length >= 61) {
          this.$message.error('请输入正确个人介绍')
          return;
        }
        let sendData = {}
        sendData.data = this.ruleForm
        sendData.fn = this._userUpdateFn
        sendData.vm = this
        req.Post('sysUser/updateUser', sendData)
      })
    },
    _userUpdateFn(data) {
      if (!data) return
      if (data.code === 200) {
        window.$getUserInfo()
        this.$message.success("更新成功!")
      }
    },
    beforeAvatarUpload() {

    },
    handleAvatarSuccess() {

    }
  },
  mounted() {
    if (!window.$global.userDetails) return
    let user = window.$global.userDetails.sysUser
    this.ruleForm.email = user.email
    this.ruleForm.sex = user.sex
    this.ruleForm.introduction = user.introduction
    this.ruleForm.nickname = user.nickname
    this.ruleForm.realname = user.realname
  }
}
</script>

<template>
  <div style="width: 100%">
    <b-container direction="vertical" class="custom-container">
      <b-row class="header-row">
        资料设置
      </b-row>
      <b-row>
        <div class="left-img-icon"></div>
        <div class="left-img-text">
          基本信息 <span class="left-img-text-small">完善个人资料时让别人认识你的第一步</span>
        </div>
      </b-row>

      <b-row>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm mt-4">
          <el-form-item label="昵 称:" prop="nickname">
            <el-input size="small" placeholder="请输入昵称" v-model="ruleForm.nickname"></el-input>
          </el-form-item>


          <el-form-item label="头 像:">

            <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="性 别:" prop="sex">
            <el-radio-group class="mt-1" v-model="ruleForm.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
              <el-radio :label="3">保密</el-radio>
            </el-radio-group>


          </el-form-item>

          <el-form-item label="个人介绍:" prop="introduction">
            <el-input type="textarea" placeholder="用一段话介绍你自己, 最多输入60字" rows="4"
                      v-model="ruleForm.introduction"></el-input>
          </el-form-item>

          <el-form-item label="修改密码:">
            <el-row>
              <el-button type="primary" size="small" @click="showUpdatePassword = true">修改</el-button>
            </el-row>
          </el-form-item>
        </el-form>


      </b-row>

      <el-divider></el-divider>
      <b-row>
        <div class="left-img-icon"></div>
        <div class="left-img-text">
          联系信息
        </div>
      </b-row>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm mt-3 w-50">
        <el-form-item label="真实姓名:" prop="realname">
          <el-input class="w-75" size="small" placeholder="请输入姓名" v-model="ruleForm.realname"></el-input>
          <div>
            用于高等教育课程证书登记与发放，不会以任何形式向第三方透露
          </div>
        </el-form-item>

        <el-form-item label="常用邮箱:" prop="email">
          <div class="d-flex w-100 justify-content-between">
            <el-input class="w-75" size="small" placeholder="请输入邮箱" v-model="ruleForm.email"></el-input>
            <!--            <div>-->
            <!--              <el-button type="text">修改邮箱</el-button>-->
            <!--            </div>-->
          </div>
          用于高等教育课程证书登记与发放，不会以任何形式向第三方透露
        </el-form-item>

        <el-form-item label="手 机 号:">
          <el-row>
            <el-col :span="6">
              {{ getPhone }}
            </el-col>
            <el-col :span="10">
              <el-button el-button type="primary" size="small" @click="showUpdatePhone = true">修改</el-button>
            </el-col>
          </el-row>
          <el-row>用于优质课程的学习提醒等服务，手机号不会对外公开，请放心</el-row>
        </el-form-item>

        <!--      <el-form-item label="QQ 号码:" class=" w-75">-->
        <!--        <el-input size="small" placeholder="请输入QQ号" v-model="ruleForm.qq"></el-input>-->
        <!--        用于课程的高效交流，方便机构及老师答疑解惑-->
        <!--      </el-form-item>-->


      </el-form>

    </b-container>

    <div class="saveBtn" @click="save">
      立即保存
    </div>
    <el-dialog
        width="450px"
        title="密码修改" :visible.sync="showUpdatePassword">
      <retrieve-your-password @change="showUpdatePassword = false" class="p-2 text-center">
      </retrieve-your-password>
    </el-dialog>

    <el-dialog
        center
        width="450px"
        title="绑定新的手机号" :visible.sync="showUpdatePhone">

      <el-row align="center">
        <el-form :model="phoneForm" status-icon :rules="rules" ref="bindPhone" class="demo-ruleForm">
          <el-form-item prop="phone">
            <el-input placeholder="请输入手机号" prefix-icon="el-icon-phone-outline" type="text"
                      v-model="phoneForm.phone"></el-input>
          </el-form-item>

          <el-form-item>
            <div class="d-flex justify-content-between">
              <div class="w-75">
                <el-input placeholder="请输入验证码" prefix-icon="el-icon-chat-line-round" type="code"
                          v-model="phoneForm.code"></el-input>
              </div>


              <el-button size="small" :disabled="codeStr !== '获取验证码'" @click="sendSms">{{ codeStr }}</el-button>

            </div>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="justify-content-center d-flex">
        <button class="login" @click="updatePhone">确定</button>
      </el-row>
    </el-dialog>

  </div>
</template>

<style scoped>
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF !important;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.demo-ruleForm {
  width: 45%;
  min-width: 400px;
}

.left-img-icon {
  width: 23px;
  height: 23px;
  background-size: 100%;
  margin: 7px 0;
  background-image: url("@/assets/image/left-img-icon.png");
}

.left-img-text {
  padding: 0 15px;
  font-weight: bold;
  font-size: 25px;
}

.left-img-text-small {
  color: #9E9E9E;
  font-size: 16px;
  margin: 0 15px;
}

.header-row {
  font-weight: bold;
  font-size: 19px;
  padding: 15px 0;
  /*p-2 font-weight-bold" style="font-size: 19px"*/
}

.custom-container {
  width: 100%;
  margin: 0 auto;
  align-items: center;
}

.saveBtn {
  width: 113px;
  height: 33px;
  position: absolute;
  right: 250px;
  bottom: 160px;
  text-align: center;
  align-content: center;
  display: flex;
  justify-content: center;
  color: white;
  line-height: 33px;
  /*禁止图标*/
  cursor: pointer;
  box-shadow: #0075E6 0 0 3px 0;
  /*background-image: url("@/assets/image/save.png");*/
  background: linear-gradient(90deg, #0075E6, #005AB1);
}

</style>
