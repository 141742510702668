import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login.vue";
import Index from "@/views/Index.vue";
import Home from "@/views/pages/home.vue";
import Setting from "@/views/pages/Setting.vue";
import Shopping from "@/views/pages/Shopping.vue";
import Order from "@/views/order/playOrder.vue";
import MyStudies from "@/views/pages/myStudies.vue";
import courseDetails from "@/views/pages/productDetails.vue";

Vue.use(VueRouter)
// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}
const routes = [
    {
        path: '/',
        name: 'main',
        redirect: '/index',
        component: Index,
        children: [
            {
                path: '/index',
                name: 'Index',
                component: Home
            },
            {
                path: '/details',
                name: 'details',
                component: courseDetails
            },
            {
                path: '/setting',
                name: 'Setting',
                component: Setting
            },
            {
                path: '/shopping',
                name: 'Shopping',
                component: Shopping
            },
            {
                path: '/order',
                name: 'Order',
                component: Order
            },
            {
                path: '/student',
                name: 'student',
                component: MyStudies
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
]

const router = new VueRouter({
    routes: routes
})

router.beforeEach((to, _, next) => {
    if (to.name === 'Login') {
        localStorage.removeItem('Authorization')
        return next()
    }
    // 根据权限 判断是否拥有当前导航权限
    // 有权限则直接放行
    // 没权限则跳转无权限路由

    return next()
})


export default router
